@import '~bootstrap/scss/bootstrap';

.btn {
  color: #c4c4c4;
  border-color: #656565;
}

.header {
  display: flex;
  justify-content: center;
}
