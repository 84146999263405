.root {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 800px;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid gray;
  
  .image {
    width: 400px;
    display: flex;
    justify-content: center;

    img {
      max-height: 200px;
      margin: 10px 18px;
    }
  }

  .resultInformation {
    display: flex;
    flex-direction: column;

    p {
      margin: 20px 0 0 0;
      text-align: center;
    }

    .bar {
      fill: #8bb639;
    }
  }
}

.dataPaper {
  padding: 20px;
  margin: 20px; 
  
  .buttonArea {
    display: flex;
    flex-wrap: wrap;
    
    > * {
      margin-right: 10px;
    }
  }
}