
.root {
  margin: 40px 20px;
  display: flex;
  flex-direction: column;
  
  .spinner {
    margin-top: 150px;
    align-self: center;
    color: #8bb639;
  }
}

.form {
    
  margin-bottom: 100px;
  
  img {
      max-height: 100px;
      margin: 10px 18px;
  }
  
  .modelSection {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      .modelInfo {
        display: flex;
        justify-content: space-between;

          .modelTextInfo {
            color: orange;

            span {
              vertical-align: middle;
              display: inline-block;
              line-height: 1.1em;
            }
          }
  
          .trainingButtons {
              align-self: flex-end;
              display: inline-block;
              text-align: end;
              justify-self: flex-end;

              button {
                margin: 3px 0;
              }
          }

      }
  }
}

.adminModal {
    border: 1px solid #333;
    padding: 10px;
}

.modalSummary {
    margin: 10px;
    padding: 10px;
    background-color: #1f1f1f;
    border-radius: 10px;
}