$breakpoint-tablet: 768px;

.item {
  width: 100%;
}


@media (min-width: $breakpoint-tablet) { /* Desktop */
  
  .removed {
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: transform 0.5s linear, height 0.5s linear, opacity 5s, z-index 0.3s;
    transform: translateY(-330px);
    height: 0;
    z-index: -10000;
  }
  
  .visible {
    width: 100%;
    position: relative;
    height: 315px;
    z-index: 1000;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: transform 0.5s linear, height 0.5s linear, opacity 5s, z-index 1s;
  }
  
  .paper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 5px solid black;
    position: relative;

    .label {
      position: absolute;
      top: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 1.3em;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
      
    button {
      width: 150px;
      height: 70px;
      margin: 10px;
    }
  }
}

@media (max-width: $breakpoint-tablet) { /* Mobile, Tablet */

  .removed {
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: transform 0.5s linear, height 0.5s linear, opacity 5s, z-index 0.3s;
    transform: translateY(-397px);
    height: 0;
    z-index: -10000;
  }
  
  .visible {
    width: 100%;
    position: relative;
    height: 397px;
    z-index: 1000;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: transform 0.5s linear, height 0.5s linear, opacity 5s, z-index 1s;
  }

  .paper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    border-top: 3px solid black;
    padding: 0 7px 0 7px;
    
    .label {
      position: absolute;
      top: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 1.3em;
      padding: 4px 0;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    button {
      width: 50%;
      margin: 7px 10px;
    }
  }
}