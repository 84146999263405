.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;

  .form {
    width: 500px;
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 20px;
    }
  }
}

.dataPaper {
  padding: 40px;
  margin: 20px; 
  
  .buttonArea {
    display: flex;
    flex-wrap: wrap;
    
    > * {
      margin-right: 10px;
    }
  }
}

.tab {
  color: #c4c4c4;

  &:hover {
    border-color: transparent !important;
    color: #fff;
  }
}

.tabContent {
  @extend .buttonArea;
  margin: 1em 0 0;
}

.imageGrid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
  list-style: none;
  margin: 0;
  padding: 0;
}

.imageTile {
  position: relative;
}

.imageButtons {
  background: rgba(0, 0, 0, .5);
  bottom: 0;
  display: none;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
}

.imageTile:hover > .imageButtons {
  display: flex;
}
