$breakpoint-tablet: 768px;


.form {
  img {
    max-height: 100px;
    margin: 10px 18px;
  }
}

@media (min-width: $breakpoint-tablet) { /* Desktop */
  
  .uploadPaper {
    padding: 40px;
    margin: 20px;
    position: relative;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    max-width: 400px;

    .preview {
        display: flex;
        flex-wrap: wrap;
    }

    .formInput {
      width: 400px;
      margin: 20px 0px;
    }

    .formButtons {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 12px;
      }
    }

    .modelsSelect {
      .title {
        font-size: 1.2em;

        svg {
          cursor: pointer;
        }
      }
    }

    .modelsButtons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 20px 0;
      justify-content: center;

      > * {
        max-width: 150px;
      }

      .spinner {
        color: #8bb639;
      }
    }
  }
  
  .fileInputHolder {
    border: solid rgba(139, 182, 57, 1) 2px;
    background-color: rgba(139, 182, 57, 0.2);
    border-radius: 5px;
    display: inline-block;
    position: relative;
    margin: 20px 0 20px 0;
    
    & > * {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 400px;
      min-height: 200px;
    }
    
    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0
    }
  }

  .popper {
    padding: 10px;
  }
}

@media (max-width: $breakpoint-tablet) { /* Mobile, Tablet */

  .uploadPaper {
    padding: 4px 10px;
    margin: 2px;
    position: relative;
  }

  .form {
    display: flex;
    flex-direction: column;
    
    .tagging {
      width: 100%;

      .formInput {
        min-width: 250px;
      }
    }

    .modelsSelect {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px;
      gap: 10px;

      .spinner {
        align-self: center;
        color: #8bb639;
      }
    }
  }
    
  .mobileFileInputHolder {

    display: flex;
    justify-content: space-evenly;
    
    button {
        width: 80px;
        height: 80px;;
        display: relative;
    }

    svg {
        position: absolute;
    }

    input {
        opacity: 0;
        min-width: 80px;
        height: 80px;
        position:relative;
        z-index: 999;
        padding: 0;
    }
  }

  .popper {
    max-width: 90%;
    margin: 0 20px;
    padding: 10px;
    font-size: 0.8em;
  }
}