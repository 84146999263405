.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    
    .mainContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 50vh;
    }
}