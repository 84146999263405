$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) { /* Desktop */
  .root {
    height: 100%;
    margin-bottom: -150px;
    display: flex;
    flex-direction: column;
    
    .list {
      overflow: hidden;
      overflow-y: scroll;
    }
    
    .undo {
      height: 80px;
      width: 95%;
      align-self: center;
      margin: 10px 10px 40px 10px;
      padding: 5px 17px;
      border: 1px solid rgba(247, 247, 247, 0.5);
      background-color: rgba(21,21,21,1);
      position: absolute;
      bottom: 0;
      text-align: right;
      z-index: 100000;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        color: red;
      }
    }
    
    .userFeedback {
      align-self: center;
      margin-top: 200px;
    }
  }
}

@media (max-width: $breakpoint-tablet) { /* Mobile, Tablet */
  .root {
    height: calc(100% - 130px);
    display: flex;
    flex-direction: column;
    position: relative;
    
    .list {
      overflow: hidden;
      overflow-y: scroll;
    }
    
    .undo {
      height: 50px;
      width: 100%;
      align-self: center;
      margin: 10px 10px 0px 10px;
      padding: 5px 5px;
      border: 1px solid rgba(247, 247, 247, 0.5);
      background-color: rgba(21,21,21,1);
      position: absolute;
      bottom: 0;
      text-align: right;
      z-index: 100000;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      button {
        color: red;
      }
    }

    .userFeedback {
      align-self: center;
      margin-top: 150px;
    }
  }
}